import { TrackEventVerb as UiTrackEventVerb } from '@updater/ui-tracker';

export type TrackEventVerb = UiTrackEventVerb | 'reset';
export interface AnalyticsObject extends Record<string, unknown> {}

export enum Events {
  PAGE_LOAD = 'page_load',
  PLANS = 'offers_page',
  VIEW_DETAILS = 'view_details',
  PROVIDERS = 'providers',
  SERVICE_SELECTION = 'service_selection',
  FILTER = 'filter',
  CHANGE_ADDRESS = 'change_address',
  INTERNET_SPEED = 'internet_speed',
  OFFERS_SORT = 'offers_sort',
  SEARCH_OFFERS_WAIT_TIME = 'search_offers_wait_time',
  SESSION_TIMEOUT = 'session_timeout',
  SESSION_TIMEOUT_PHONE = 'session_timeout_phone',
  VERIFY_ADDRESS_UNIT_NEEDED = 'verify_address_unit',
  VERIFY_ADDRESS = 'verify_address',
  VERIFY_ADDRESS_PHONE = 'verify_address_phone',
  ADDRESS_SUGGESTIONS = 'address_suggestions',
  ADDRESS_SUGGESTIONS_VERIFY_ADDRESS = 'address_suggestions_verify_address',
  SKIP_QUESTIONS = 'skip_questions',
  CALL_BUTTON_GENERIC = 'call_button',
  CALL_BUTTON_MOBILE_HEADER = 'mobile_call_button',
  CALL_BUTTON_DESKTOP_HEADER = 'desktop_call_button',
  CALL_BUTTON_ERROR_PAGE = 'error_phone',
  PRIVACY_AND_TERMS_CONSENT = 'privacy_and_terms_consent',
  APPFOLIO_LANDING = 'appfolio_landing',
  RATE_LANDING = 'rate_landing',
}
