import { ShopType, CheckoutType, MultiProviderConfig } from 'types';
import { ProviderConfigList } from 'config/providers';
import { TvInternetOffer } from '@updater/consumer-graph';
import { RatePartnerTheme } from 'config/themes';

export const RatePartner: MultiProviderConfig = {
  name: 'RatePartner',
  micrositeCode: 'ratepartner',
  shopType: ShopType.MULTI_PROVIDER,
  domain: 'rate.updater.com',
  homepage: 'https://rate.updater.com',
  hideFirstPageBackButton: false,
  hideSupportInfoBox: true,
  title: 'Compare and shop for internet & TV with Rate Insurance',
  phoneNumber: '844-709-2013',
  leadModePhoneNumber: '844-718-8730',
  defaultCheckoutType: CheckoutType.LEAD_MODE,
  defaultTheme: RatePartnerTheme,
  hasUpdaterCobranding: false,
  needsDcSession: false,
  isIFramed: false,
  updaterCrossSellActive: false,
  source: 'RatePartner',
  noOffersRetry: 2,
  enableMicrositeLanding: true,
  enableGenerateQualOnLoad: true,
  //TVI-1976: Suppress EarthLink and TDS
  providers: ProviderConfigList.filter(
    (provider) =>
      provider.providerCode !== 'earthlink' && provider.providerCode !== 'tds'
  ),
  //TVI-1976: Enable guest-buy-flow
  guestBuyflowUrls: {
    development: 'http://bridgevine.io:3000/v2/checkout',
    staging: 'https://guest-buyflow-app.staging.infra.updatron.com/v2/checkout',
    qa: 'https://guest-buyflow-app.staging.infra.updatron.com/v2/checkout',
    production: 'https://cart.xfinityspecial.com/v2/checkout',
  },
  getCheckoutType: (offer: TvInternetOffer) => {
    // This is a stopgap for Comcast issues where the modem
    // price is incorrect for certain offers.  We have no mechanism
    // for offer-level logic in the guest-buyflow app.  Our only
    // solution is to route users to Lead Mode if certain offers can't
    // be shown in the async flow.
    const excludedOffers = [
      '9626220318',
      '9626150272',
      '9626101594',
      '9626101624',
    ];
    const exclude =
      excludedOffers.includes(offer.providerOfferId) ||
      offer?.providerCode !== 'xfinity';
    console.log(`[XfinitySpecial] providerOfferId ${offer.providerOfferId}`);
    if (exclude) {
      console.log(`[XfinitySpecial] async exclusion`);
    }

    const { internet, phone, tv } = offer.products;

    const hasOnlyInternet = internet && !phone && !tv;
    if (hasOnlyInternet && !exclude) {
      return CheckoutType.ASYNC;
    }
    return CheckoutType.LEAD_MODE;
  },
  asyncConfig: {
    theme: 'rate',
    supportPhoneNumber: '844-709-2013',
    headerLogoLinkUrl: 'https://rate.updater.com',
  },
  generateQualDefaults: ({ uuid }) => {
    return {
      provider: RatePartner.micrositeCode,
      promoId: '5002473',
      uuid,
    };
  },
};
