import { getGenerateQualOnLoadMicrosites } from 'config/sharedFunctions';
import { useStore } from 'hooks';
import { useEffect } from 'react';
import { Qual } from 'types';
import { useShallow } from 'zustand/react/shallow';
import { v4 as uuid } from 'uuid';
import { MicrositeList } from 'config/microsites';

export function useQual(): Qual {
  const { qual, setQual } = useStore(
    useShallow((store) => ({ qual: store.qual, setQual: store.setQual }))
  );

  /**
   * Generate qual defaults on load if qual is empty.
   * Only for microsites that need to generate qual defaults.
   */
  useEffect(() => {
    const isEmptyQualObject = Object.keys(qual).length === 0;
    if (typeof window !== 'undefined' && isEmptyQualObject) {
      // Remove "staging-" prefix
      const hostname = window.location.hostname?.replace(/^staging-/, '');

      const microsites = getGenerateQualOnLoadMicrosites(MicrositeList);

      if (microsites?.[hostname]) {
        const generatedQualDefaults = microsites[
          hostname
        ]?.generateQualDefaults({
          uuid: uuid(),
        });
        setQual(generatedQualDefaults);
      }
    }
  }, [qual, setQual]);

  return qual;
}
