import { MicrositeCustomTheme, MicrositeTheme } from 'types';
import { baseTheme } from '../baseTheme';
import { Palette } from '@updater/ui-uds';

// ==================================
// BEGIN CUSTOM THEMING FOR MICROSITE
// ==================================

const customTheme: MicrositeCustomTheme = {
  name: 'RatePartnerTheme',
  code: 'RatePartner',
  colors: {
    headerBackground: '#000000',
    headerText: '#FFFFFF',
    headerBorder: '#CCCCCC',
    background: '#FFFFFF',
    text: '#28293C',
    footerBackground: '#000000',
    footerText: '#FFFFFF',
    footerBorder: '#E5E5E5',
    progressBar: '#CF3338',
  },
  logoSize: {
    width: '168px',
    height: '40px',
  },
  baseColors: {
    link: '#CF3338',
    cta: '#CF3338',
    hover: '#CF3338',
  },
};

// ==================================
// END CUSTOM THEMING FOR MICROSITE
// ==================================

const mergedTheme = Object.assign(baseTheme, {
  colors: {
    ...baseTheme.colors,
    ...customTheme.baseColors,
  },
});

export const RatePartnerTheme: MicrositeTheme = {
  ...mergedTheme,
  customTheme,
};
