import { MicrositeConfigType } from 'types';
import { AdAction } from './AdAction';
import { AdActionSpectrumPartner } from './AdActionSpectrumPartner';
import { AdActionATTPartner } from './AdActionATTPartner';
import { AppFolio } from './AppFolio';
import { ATTSpecial } from './ATTSpecial';
import { ApartmentList } from './ApartmentList';
import { BrightspeedSpecial } from './BrightspeedSpecial';
import { BuyEarthlink } from './BuyEarthlink';
import { Centerfield } from './Centerfield';
import { CenturyLinkSpecial } from './CenturyLinkSpecial';
import { CincinnatiBellSpecial } from './CincinnatiBellSpecial';
import { CoxCableSpecial } from './CoxCableSpecial';
import { CoxAuthorizedOffers } from './CoxAuthorizedOffers';
import { CordlessATTPartner } from './CordlessATTPartner';
import { CordlessCoxPartner } from './CordlessCoxPartner';
import { CordlessXfinityPartner } from './CordlessXfinityPartner';
import { Demo } from './Demo';
import { DirecTVSpecials } from './DirecTVSpecials';
import { DishIFramed } from './DishIFramed';
import { MyDishIFramed } from './MyDishIFramed';
import { FluentIFramed } from './FluentIFramed';
import { FrontierAuthorizedOffers } from './FrontierAuthorizedOffers';
import { OptimumOffers } from './OptimumOffers';
import { OrderWindstream } from './OrderWindstream';
import { QuantumFiber } from './QuantumFiber';
import { RatePartner } from './RatePartner';
import { SpectrumSpecial } from './SpectrumSpecial';
import { TDSAuthorizedOffers } from './TDSAuthorizedOffers';
import { Turbotenant } from './Turbotenant';
import { UpdaterInternet } from './UpdaterInternet';
import { VerizonDeals } from './VerizonDeals';
import { WOWWaySpecial } from './WOWWaySpecial';
import { XfinitySpecial } from './XfinitySpecial';

const list = [
  AdAction,
  AdActionSpectrumPartner,
  AdActionATTPartner,
  AppFolio,
  ApartmentList,
  ATTSpecial,
  BrightspeedSpecial,
  BuyEarthlink,
  Centerfield,
  CenturyLinkSpecial,
  CincinnatiBellSpecial,
  CoxCableSpecial,
  CoxAuthorizedOffers,
  CordlessATTPartner,
  CordlessCoxPartner,
  CordlessXfinityPartner,
  DirecTVSpecials,
  DishIFramed,
  MyDishIFramed,
  FluentIFramed,
  FrontierAuthorizedOffers,
  OrderWindstream,
  OptimumOffers,
  QuantumFiber,
  RatePartner,
  SpectrumSpecial,
  TDSAuthorizedOffers,
  Turbotenant,
  UpdaterInternet,
  VerizonDeals,
  WOWWaySpecial,
  XfinitySpecial,
  Demo,
];

export { list as MicrositeList };

export * from './AdAction';
export * from './AdActionSpectrumPartner';
export * from './AdActionATTPartner';
export * from './AppFolio';
export * from './ATTSpecial';
export * from './ApartmentList';
export * from './BrightspeedSpecial';
export * from './BuyEarthlink';
export * from './Centerfield';
export * from './CenturyLinkSpecial';
export * from './CincinnatiBellSpecial';
export * from './CoxAuthorizedOffers';
export * from './CoxCableSpecial';
export * from './CordlessATTPartner';
export * from './CordlessCoxPartner';
export * from './CordlessXfinityPartner';
export * from './Demo';
export * from './DirecTVSpecials';
export * from './DishIFramed';
export * from './FluentIFramed';
export * from './FrontierAuthorizedOffers';
export * from './OptimumOffers';
export * from './OrderWindstream';
export * from './RatePartner';
export * from './QuantumFiber';
export * from './SpectrumSpecial';
export * from './TDSAuthorizedOffers';
export * from './Turbotenant';
export * from './UpdaterInternet';
export * from './VerizonDeals';
export * from './WOWWaySpecial';
export * from './XfinitySpecial';

export function getMicrositeConfigFromCode(
  code: string
): MicrositeConfigType | undefined {
  return list.find((config) => {
    return config.micrositeCode === code;
  });
}
